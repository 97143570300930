.html {
        background-color: #282c34;
    height: 100%;
}
svg {
    height: 100%;
    width: 100%;
}
.App {
    text-align: center;
    background-color: #282c34;
    min-height: 100vh;
}

.App-header {
  background-color: #282c34;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.form {
    border: none;
    color: #000;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
}
.textFields {
    width: 40%;

}
.selSizer {
    width:110px;
}
.margin10 {
    margin: 10px;
}
.addButton {
    height: 50px;
    width: 50px;
    background-color: rgb(212, 77, 77);
    border-radius: 15px;
    text-align: center;
    font-size: x-large;
    margin: 10px;

    border: none;
}

.addButton2 {
    height: 50px;
    width: 50px;
    background-color: rgb(77, 212, 106);
    border-radius: 15px;
    text-align: center;
    font-size: x-large;
    margin: 10px;

    border: none;
}
    .addsvg {
        opacity:0.50;
    }
    .addsvg:hover {
        opacity:1.00;
    }

.form-control {
    margin:.5rem;
}
.mainContent {
  background-color: #bbb;
  height: 100%;
}

.leftCol {
    background-color: #282c34;
    width: 100%;
    float: left;
    height: 100%;
}

.rightCol {
    background-color: #282c34;
    width: 20%;
    float: right;
    height: 100%;
}

.stratCard {
    height: 100px;
    background-color: rgb(60, 60, 220);
    margin: 2%;
    padding: 20px;
    border-radius: 25px;
    text-align: center;
    width: 90%;
}

.incomeCard {
    height: auto;
    background-color: rgb(77, 212, 106);
    margin: 2%;
    padding: 20px;
    border-radius: 25px;
}

.incomeClosed {
    height: 70px;
    background-color: rgb(77, 212, 106);
    margin: 2%;
    padding: 20px;
    border-radius: 25px;
}

.expenseCard{

  height: auto;
  background-color: rgb(212, 77, 77);
  margin: 2%;
  padding: 20px;
  border-radius: 25px;
}

.expenseClosed {
    height: 70px;
    background-color: rgb(212, 77, 77);
    margin: 2%;
    padding: 20px;
    border-radius: 25px;
    font-size:20px;
    font-weight:500;
}

.incomeTotalCard {
    height: 70px;
    background-color: rgb(233, 196, 106);
    margin: 2%;
    padding: 20px;
    border-radius: 25px;
    text-align: center;
}

.incomeSplitCard {
    height: 40px;
    background-color: #fff;
    padding: 6px;
    border-radius: 10px;
    outline-color: #282c34;
    outline-width: 1px;
    outline-style: solid;
}
.incomeSplitContain {
    margin: 2%;
    text-align: center;
    font-weight: 500;
    padding: 6px;
}

.percentSlider {
    margin: 2%;
}
.plus {
    height: 100%;
    width:100%;
}